import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react'
import PropTypes from 'prop-types'
import Player from '@vimeo/player'
import IconVideoPlayButton from '../svgs/IconVideoPlayButton'
import {
  VimeoPlayerWrapper,
  VimeoPlayerMain,
  VimeoPlayerEmbed,
  VimeoPlayerOverlay,
  VimeoPlayButton,
} from './index.style'
import { GatsbyImage } from 'gatsby-plugin-image'
import { StoreState } from '../../Store'

const VimeoPlayer = ({
  id,
  fluid = false,
  autoplay = false,
  autopause = true,
  loop = false,
  title = false,
  muted = false,
  controls = true,
  playState = false,
  endPlaying = false,
  rounded = true,
  onTimeUpdate,
  endedCallback,
  thumbnail,
  maxWidthVW = 90,
  maxHeightVH = 90,
}) => {
  const store = useContext(StoreState)
  const videoRef = useRef()
  const videoPlayer = useRef()
  const [loading, setLoading] = useState(true)
  const [playing, setPlaying] = useState(false)
  const [dimensions, setDimensions] = useState(null)

  const play = () => {
    videoPlayer.current
      .play()
      .then(() => {
        if (videoRef.current) {
          setPlaying(true)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const pause = callback => {
    videoPlayer.current
      .pause()
      .then(() => {
        if (videoRef.current) {
          setPlaying(false)
        }

        if (callback) {
          callback()
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const buildPlayer = useCallback(() => {
    if (!videoRef.current) return

    setLoading(true)

    videoPlayer.current = new Player(videoRef.current, {
      id,
      autoplay,
      autopause,
      muted,
      controls,
      loop,
      title,
      byline: false,
    })

    Promise.all([
      videoPlayer.current.getVideoWidth(),
      videoPlayer.current.getVideoHeight(),
    ]).then(dimensions => {
      if (videoRef.current) {
        setDimensions([dimensions[0], dimensions[1]])
        window.scroll.update()
      }
    })

    videoPlayer.current.on('loaded', () => {
      if (videoRef.current) {
        setLoading(false)
      }
    })
    videoPlayer.current.on('pause', () => {
      if (videoRef.current) {
        setPlaying(false)
      }
    })
    videoPlayer.current.on('play', () => {
      if (videoRef.current) {
        setPlaying(true)
      }
    })

    if (onTimeUpdate) {
      videoPlayer.current.on('timeupdate', timeupdate => {
        if (videoRef.current && timeupdate.percent > 0) {
          setPlaying(true)
        }
        onTimeUpdate(timeupdate.percent)
      })
    }

    if (endedCallback) {
      videoPlayer.current.on('ended', () => {
        endedCallback()
      })
    }
  }, [
    id,
    autoplay,
    autopause,
    loop,
    muted,
    controls,
    title,
    onTimeUpdate,
    endedCallback,
  ])

  useEffect(() => {
    buildPlayer()

    return () => {
      if (videoPlayer.current) {
        videoPlayer.current.off('loaded')
        videoPlayer.current.off('pause')
        videoPlayer.current.off('play')
        videoPlayer.current.off('timeupdate')
        videoPlayer.current.off('ended')
      }
    }
  }, [buildPlayer])

  useEffect(() => {
    if (!videoPlayer.current) return

    if (!playState) {
      pause()
    } else {
      play()
    }
  }, [playState])

  useEffect(() => {
    if (!videoPlayer.current) return

    if (endPlaying) {
      pause()
    }
  }, [endPlaying])

  useEffect(() => {
    if (videoPlayer.current) {
      pause(() => {
        videoPlayer.current.destroy().then(function () {
          buildPlayer()
        })
      })
    }
  }, [id, buildPlayer])

  useEffect(() => {
    if (videoPlayer.current) {
      videoPlayer.current.pause()
    }
  }, [store.homeTabsSwitchIndex])

  return (
    <VimeoPlayerWrapper
      fluid={fluid}
      dimensions={dimensions}
      maxWidthVW={maxWidthVW}
      maxHeightVH={maxHeightVH}
    >
      <VimeoPlayerMain dimensions={dimensions} rounded={rounded}>
        {thumbnail && (
          <VimeoPlayerOverlay
            aria-label="Play video"
            playing={playing}
            onClick={play}
          >
            <GatsbyImage image={thumbnail.gatsbyImageData} alt="" />

            <VimeoPlayButton isLoading={loading} aria-label="Play">
              <IconVideoPlayButton />
            </VimeoPlayButton>
          </VimeoPlayerOverlay>
        )}

        <VimeoPlayerEmbed
          ref={videoRef}
          playing={playing}
          autoplay={autoplay}
        />
      </VimeoPlayerMain>
    </VimeoPlayerWrapper>
  )
}

VimeoPlayer.propTypes = {
  id: PropTypes.string.isRequired,
  fluid: PropTypes.bool,
  playState: PropTypes.bool,
  endPlaying: PropTypes.bool,
  autoplay: PropTypes.bool,
  autopause: PropTypes.bool,
  muted: PropTypes.bool,
  controls: PropTypes.bool,
  loop: PropTypes.bool,
  rounded: PropTypes.bool,
  title: PropTypes.string,
  onTimeUpdate: PropTypes.func,
  endedCallback: PropTypes.func,
  thumbnail: PropTypes.object,
  maxWidthVW: PropTypes.number,
  maxHeightVH: PropTypes.number,
}

export default VimeoPlayer
